function BudgetApp() {
  this.checkSetup();

  var weeklyBudget,
      balanceValue,
      weeklyTotal,
      reset;
      this.uid = null;   
  // Shortcuts to DOM Elements.
  this.purchaseList = document.getElementById('purchases');
  this.messageForm = document.getElementById('purchase-form');
  this.purchaseAmount = document.getElementById('purchase-amount');
  this.purchaseName = document.getElementById('purchaseName');
  this.balanceDom - document.getElementById('remaining-balance');
  this.budgetPeriod = document.getElementById('budget-period');
  this.submitButton = document.getElementById('submit-purchase');
  this.userPic = document.getElementById('user-pic');
  this.userName = document.getElementById('user-name');
  this.userUid = document.getElementById('user-uid');
  this.signInButton = document.getElementById('sign-in');
  this.signOutButton = document.getElementById('sign-out');
  this.loginContainer = document.getElementById('login');
  this.mainContainer = document.getElementById('main-container');
  this.signInSnackbar = document.getElementById('signin-snackbar');

  // Save & remove messages.
  this.messageForm.addEventListener('submit', this.savePurchase.bind(this));

  // Sets up shortcuts to Firebase features and initiate firebase auth.
  BudgetApp.prototype.initFirebase = function() {
    // Shortcuts to Firebase SDK features.
    this.auth = firebase.auth();
    this.database = firebase.database();
    this.storage = firebase.storage();
    // Initiates Firebase auth and listen to auth state changes.
    this.auth.onAuthStateChanged(this.onAuthStateChanged.bind(this));

    //emitter.setMaxListeners(15);
    
  };
  // Reset Week
  $('#resetWeek').on('click', function(){
    if (confirm("Reset the weekly budget?")) {
      BudgetApp.resetWeek();
    }
  });

  //Remove item 
  $(document).on('click', 'i[class*="fa-times"]', function(){
    var that = this,
      remove = "remove",  
      id = this.dataset.itemid,
      value = parseFloat(this.dataset.itemcost),
      uid = $('#user-uid').text();
    if(confirm("Are you sure you want to remove this item?")) {
      $('#' + id).remove();
      BudgetApp.updateBalance(value, remove);
      BudgetApp.removePurchase(id, uid);
    }
  });
  $(document).ready(function(){
    var data = {
        message: "",
        timeout: 2000
      };
      $('#budget-amount').editable({
        type: "text",
        pk: 1,
        mode: "inline",
        display: function(value, res){
          return false;
        },
        success: function(res, newValue) {
          var amount = parseInt(newValue);
          $(this).html("$" + amount);
          BudgetApp.userRef.update({
            "weeklyBudget": amount
          });
          data.message = "Weekly Budget updated to $" + amount;
          BudgetApp.updateBalance(amount, "update"); 
          signInSnackbar.MaterialSnackbar.showSnackbar(data);
        },
        error: function(res){
          data.message = "There was an error updating.";
          signInSnackbar.MaterialSnackbar.showSnackbar(data);
        }
      });
  });
  

  //Sign In / Out
  this.signOutButton.addEventListener('click', this.signOut.bind(this));
  this.signInButton.addEventListener('click', this.signIn.bind(this));

  this.initFirebase();
}

// Round values
BudgetApp.prototype.round = function(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
};
//Update balance
BudgetApp.prototype.updateBalance = function(value, type) {
  if (type === "add"){
    balanceValue -= value;
    weeklyTotal += value;
  } else if (type === "update") {
    balanceValue = value - weeklyTotal;
  } else {
    balanceValue += value;
    weeklyTotal -= value;
  }
  balanceValue = BudgetApp.round(balanceValue, 2);
  weeklyTotal = BudgetApp.round(weeklyTotal, 2);

  this.dbRemainBalance = BudgetApp.database.ref('users/' + this.uid);
  this.dbRemainBalance.off();

  this.userRef.update({
      "weeklyTotal": weeklyTotal
  });
  this.dbRemainBalance.update({
    "balanceValue": balanceValue
  });
  $('#remaining-balance').text("$" + balanceValue);
};

BudgetApp.prototype.getCurrentPeriod = function() {
  var monday = moment().startOf('isoWeek').format(),
      sunday = moment().endOf('isoWeek').format(),
      prevMonday = moment().startOf('isoWeek').day(-7).format(),
      prevSunday = moment().endOf('isoWeek').day(-7).format();
  this.currentRef = this.database.ref('currentWeek');
  this.currentRef.off();

  var setCurrentDate = function(data) {
    var start = data.val().start,
        end = data.val().end;
        reset = data.val().weekReset,
    this.budgetPeriod.innerText = "Budget Period: " + moment(monday).format('ddd, MMMM Do YYYY') + " - " + moment(sunday).format('ddd, MMMM Do YYYY');

  }.bind(this);
  var two = moment().endOf('isoWeek').format('MM/DD/YYYY HH:mm:ss');
  //console.log(two);

  $('#countdown').countdown(two)
  .on('update.countdown', function(event) {
    $(this).html(event.strftime('%-d Days %H hours %M minutes %S seconds'));
  })
  .on('finish.countdown', function(event) {
    alert("Reset");
    BudgetApp.resetWeek();
  });

  this.currentRef.on('value', setCurrentDate);
  this.currentRef.update({
    "start": monday,
    "end": sunday
  });

  // Set Previous Week
  this.prevRef = this.database.ref('prevWeek');
  this.prevRef.off();
  
  this.prevRef.update({
    "start": prevMonday,
    "end": prevSunday
  });
};

BudgetApp.prototype.resetWeek = function() {
  // When week is over, reset purchases & remaining balance
  while(this.purchaseList.hasChildNodes()){
    this.purchaseList.removeChild(this.purchaseList.lastChild);
  }
  this.userRef.update({
    "balanceValue": weeklyBudget,
    "weeklyTotal": 0
  });
};

// Loads chat messages history and listens for upcoming ones.
BudgetApp.prototype.loadMessages = function(uid) {
  this.purchasesRef = this.database.ref('users/' + uid + '/purchases');
  // Make sure we remove all previous listeners.
  this.purchasesRef.off();
  
  // var setMessage = function(data) {
  //   var val = data.val();
  //   this.displayMessage(data.key, val.name, val.cost, val.date);
  // }.bind(this);
  // this.purchasesRef.on('child_added', setMessage);
  // this.purchasesRef.on('child_changed', setMessage);
  
  this.mainContainer.setAttribute("style", "display:block");

  var monday = moment().startOf('isoWeek').format(),
      sunday = moment().endOf('isoWeek').format();

	// Only display purchases from the current week      
  this.purchasesRef.orderByChild("date").startAt(monday).endAt(sunday)
    .on("value", function(snapshot){
      snapshot.forEach(function(data){
        var val = data.val();
        var date = moment(val.date).format("ddd, MMMM Do YYYY, h:mm a");
        BudgetApp.displayMessage(data.key, val.name, val.cost, date);
      });
    });
};

BudgetApp.prototype.loadValues = function (uid) {
  this.userRef = this.database.ref('users/' + uid);
  var setBalance = function(data) {
    balanceValue = data.val().balanceValue,
    weeklyBudget = data.val().weeklyBudget;
    weeklyTotal = data.val().weeklyTotal;
    $('#remaining-balance').text("$" + balanceValue);
    $('#budget-amount').text("$" + weeklyBudget);
  }.bind(this);
  this.userRef.on('value', setBalance);
};

// Saves a new purchase on the Firebase DB.
BudgetApp.prototype.savePurchase = function(e) {
  e.preventDefault();
  var date = moment().format();
  if (this.purchaseName)
  // Check that the user entered a value and is signed in.
  if (this.purchaseAmount.value > 0 && this.checkSignedInWithMessage()) {
    var cost = this.purchaseAmount.value;
    cost = BudgetApp.round(cost, 2);
    this.purchasesRef.push({
      name: this.purchaseName.value,
      cost: cost,
      date: date
    }).then(function() {
      BudgetApp.updateBalance(cost, "add");
      this.purchaseAmount.value = null;
      this.purchaseName.value = null;
    }.bind(this)).catch(function(error) {
      console.error('Error writing new message to Firebase Database', error);
    });
  } else {
    var data = {
      message: "",
      timeout: 4000
    };
    data.message = "You cannot enter a negative value";
    signInSnackbar.MaterialSnackbar.showSnackbar(data);  
  }
};

// Removes a purchase from the Firebase DB.
BudgetApp.prototype.removePurchase = function(id, uid) {
  if (this.checkSignedInWithMessage()) {
    this.removeRef = this.database.ref('users/' + uid + '/purchases');
    this.removeRef.child(id).remove().then(function() {
      BudgetApp.updateBalance(this.purchaseAmount.value, "add");
    }.bind(this)).catch(function(error) {
      console.error('Error writing new message to Firebase Database', error);
    });

  }
};

// Signs-in Friendly Chat.
BudgetApp.prototype.signIn = function() {
  // Sign in Firebase using popup auth and Google as the identity provider.
  var provider = new firebase.auth.GoogleAuthProvider();
  this.auth.signInWithPopup(provider);
};

// Signs-out of Friendly Chat.
BudgetApp.prototype.signOut = function() {
  // Sign out of Firebase.
  this.auth.signOut();
};

BudgetApp.prototype.checkIfCreated = function(user) {
  this.checkRef = this.database.ref('users');
  this.checkRef.off();

  this.checkRef.once("value", function(snapshot) {
    if (snapshot.hasChild(user.uid)){
      var data = {
        message: 'Welcome Back, ' + user.displayName + '!',
        timeout: 2000
      };
      signInSnackbar.MaterialSnackbar.showSnackbar(data);
    } else {
      var userLocation = BudgetApp.database.ref('users');
      var newUser = userLocation.push();
      newUser.set({       
          displayName: user.displayName,
          userEmail: user.email,
          uid: user.uid,
          balanceValue: 0,
          weeklyBudget: 0,
          weeklyTotal: 0
       });
      newUser.once('value', function(snapshot){
        userLocation.child(user.uid).set(snapshot.val());
        newUser.remove();
      });
      BudgetApp.loadValues(user.uid);
        data = {
        message: 'Welcome, ' + user.displayName + '! Please make sure to set your weekly budget in the top right corner to start.',
        timeout: 5000
      };
      signInSnackbar.MaterialSnackbar.showSnackbar(data);
    }
  });
};

// Triggers when the auth state change for instance when the user signs-in or signs-out.
BudgetApp.prototype.onAuthStateChanged = function(user) {
  this.checkIfCreated(user);
  if (user) { // User is signed in!
    var newUser = this.auth.token;
    // Get profile pic and user's name from the Firebase user object.
    var profilePicUrl = user.photoURL,   
        userName = user.displayName,       
        userEmail = user.email;
        if (this.uid === null) {
          this.uid = user.uid;
        } 
    // Set the user's profile pic and name.
    this.userPic.style.backgroundImage = 'url(' + profilePicUrl + ')';
    this.userName.textContent = userName;
    this.userUid.textContent = this.uid;

    // Show user's profile and sign-out button.
    this.userName.removeAttribute('hidden');
    this.userPic.removeAttribute('hidden');
    this.signOutButton.removeAttribute('hidden');
    this.mainContainer.removeAttribute('hidden');

    // Hide sign-in button.
    this.signInButton.setAttribute('hidden', 'true');
    
    //Show & hide login & main containers
    this.loginContainer.setAttribute('hidden', 'true');
    //this.mainContainer.removeAttribute('hidden');

    // We load currently existing chant messages.
    this.getCurrentPeriod();
    this.loadMessages(this.uid);
    this.loadValues(this.uid);

    // We save the Firebase Messaging Device token and enable notifications.
    this.saveMessagingDeviceToken();
  } else { // User is signed out!
    // Hide user's profile and sign-out button.
    this.userName.setAttribute('hidden', 'true');
    this.userPic.setAttribute('hidden', 'true');
    this.signOutButton.setAttribute('hidden', 'true');
    this.loginContainer.removeAttribute('hidden');
    this.mainContainer.setAttribute('hidden', 'true');

    //Remove appended data
    while(this.purchaseList.hasChildNodes()){
      this.purchaseList.removeChild(this.purchaseList.lastChild);
    }
    this.userPic.style.backgroundImage = 'url("images/profile_placeholder.png")';
    this.userName.textContent = "";
    //this.userUid.textContent = "";
    uid = null;

    // Show sign-in button.
    this.signInButton.removeAttribute('hidden');
  }
};

// Returns true if user is signed-in. Otherwise false and displays a message.
BudgetApp.prototype.checkSignedInWithMessage = function() {
  // Return true if the user is signed in with Firebase
  if (this.auth.currentUser) {
    return true;
  }

  // Display a message to the user using a Toast.
  var data = {
    message: 'You must sign-in first',
    timeout: 2000
  };
    signInSnackbar.MaterialSnackbar.showSnackbar(data);
  return false;
};

// Saves the messaging device token to the datastore.
BudgetApp.prototype.saveMessagingDeviceToken = function() {
  firebase.messaging().getToken().then(function(currentToken) {
    if (currentToken) {
      //console.log('Got FCM device token:', currentToken);
      // Saving the Device Token to the datastore.
      firebase.database().ref('/fcmTokens').child(currentToken)
          .set(firebase.auth().currentUser.uid);
    } else {
      // Need to request permissions to show notifications.
      this.requestNotificationsPermissions();
    }
  }.bind(this)).catch(function(error){
    console.error('Unable to get messaging token.', error);
  });
};

// Requests permissions to show notifications.
BudgetApp.prototype.requestNotificationsPermissions = function() {
  console.log('Requesting notifications permission...');
  firebase.messaging().requestPermission().then(function() {
    // Notification permission granted.
    this.saveMessagingDeviceToken();
  }.bind(this)).catch(function(error) {
    console.error('Unable to get permission to notify.', error);
  });
};

// Resets the given MaterialTextField.
BudgetApp.resetMaterialTextfield = function(element) {
  element.value = '';
  element.parentNode.MaterialTextfield.boundUpdateClassesHandler();
};

// Template for messages.
var template =
    '<div class="well">' +
      '<div id="datestamp" class="pull-left clearfix"></div>' +
      '<div class="clearfix">' +
        '<span class="purchase-cost pull-right"></span>' +
      '</div>' +
      '<div class="name"></div>' +
    '</div>';

// A loading image URL.
BudgetApp.LOADING_IMAGE_URL = 'https://www.google.com/images/spin-32.gif';

// Displays a Message in the UI.
BudgetApp.prototype.displayMessage = function(key, name, cost, date) {
  var div = document.getElementById(key);
  // If an element for that message does not exists yet we create it.
  if (!div) {
    var container = document.createElement('div');
    container.innerHTML = template; 
    div = container.firstChild;
    div.setAttribute('id', key);
    this.purchaseList.prepend(div);
  }
  if (date) {
    var dateStamp = div.querySelector("#datestamp");
    dateStamp.innerHTML = (name + " <br>" + date);  
  }
  var messageElement = div.querySelector('.purchase-cost');
  if (cost) { // If the message is text.
    messageElement.innerHTML = ('$' + cost + ' <a href="#"><i id="remove-purchase-"' + key + '" data-itemid="'+ key +'" data-itemcost="'+ cost +'" class="fa fa-lg fa-times text-danger"></i></a>');
    // Replace all line breaks by <br>.
    messageElement.innerHTML = messageElement.innerHTML.replace(/\n/g, '<br>');
  }
};


// Checks that the Firebase SDK has been correctly setup and configured.
BudgetApp.prototype.checkSetup = function() {
  if (!window.firebase || !(firebase.app instanceof Function) || !window.config) {
    window.alert('You have not configured and imported the Firebase SDK. ' +
        'Make sure you go through the codelab setup instructions.');
  } else if (config.storageBucket === '') {
    window.alert('Your Cloud Storage bucket has not been enabled. Sorry about that. This is ' +
        'actually a Firebase bug that occurs rarely. ' +
        'Please go and re-generate the Firebase initialisation snippet (step 4 of the codelab) ' +
        'and make sure the storageBucket attribute is not empty. ' +
        'You may also need to visit the Storage tab and paste the name of your bucket which is ' +
        'displayed there.');
  }
};

window.onload = function() {
  window.BudgetApp = new BudgetApp();

  // var today = moment().format("dddd");
  // setTimeout(function(){
  //   if (today === "Monday" && reset === false) {
  //     if(confirm("Looks like today is Monday, do you want to reset the week?")) {
  //       BudgetApp.resetWeek();
  //       BudgetApp.currentRef.update({
  //         weekReset: true
  //       });
  //     }
  //   }
  // }, 2000);
};
